export default {
  init() {
    const selector = {
      EDIT_BUTTON: '.js-contactBlockEditButton',
      CANCEL_BUTTON: '.js-contactBlockCancel',
      CONTACT_BLOCK_BODY: '.contactBlock_body',
      ADD_BUSINESS_DETAILS_BUTTON: '.js-addBusinessDetailsButton',
      BUSINESS_DETAILS: '.js-businessDetails',
      CANCEL_BUSINESS_DETAILS_BUTTON: '.js-cancelAddBusinessDetailsButton',
      ADD_ADDRESS_BUTTON: '.js-addAddressButton',
      ADD_ADDRESS_BLOCK: '.js-addAddressBlock',
      CANCEL_ADD_ADDRESS_BUTTON: '.js-cancelAddAddressButton'
    };

    const classname = {
      HIDDEN: 'contactBlock_body-hidden',
    };

    const $editButtons = $(selector.EDIT_BUTTON);

    $editButtons.on('click', e => {
      e.preventDefault();

      $(e.target).closest(selector.CONTACT_BLOCK_BODY).addClass(classname.HIDDEN)
        .siblings().filter('.contactBlock_body-edit').removeClass(classname.HIDDEN);
    });

    const $cancelButtons = $(selector.CANCEL_BUTTON);

    $cancelButtons.on('click', e => {
      e.preventDefault();

      $(e.target).closest(selector.CONTACT_BLOCK_BODY).addClass(classname.HIDDEN)
        .siblings().filter('.contactBlock_body-show').removeClass(classname.HIDDEN);
    });

    $(selector.ADD_BUSINESS_DETAILS_BUTTON).on('click', event => {
      event.preventDefault();

      $(event.target).hide();
      $(selector.BUSINESS_DETAILS).show();
    });

    $(selector.CANCEL_BUSINESS_DETAILS_BUTTON).on('click', event => {
      event.preventDefault();

      $(selector.ADD_BUSINESS_DETAILS_BUTTON).show();
      $(selector.BUSINESS_DETAILS).hide();
    });

    $(selector.ADD_ADDRESS_BUTTON).on('click', event => {
      event.preventDefault();

      $(selector.ADD_ADDRESS_BLOCK).show();
      $([document.documentElement, document.body]).animate({
        scrollTop: $(selector.ADD_ADDRESS_BLOCK).offset().top
      }, 300);
    });

    $(selector.CANCEL_ADD_ADDRESS_BUTTON).on('click', event => {
      event.preventDefault();

      $(selector.ADD_ADDRESS_BLOCK).hide();
    });
  }
}