export default {
  init() {
    const $containers = $('.js-scrollGradient');

    $containers.each((_, container) => {
      const $container = $(container);
      const $gradient = $(container).parent().find('.js-scrollGradient-gradient');
      
      function checkScroll() {
        const contentHeight = $container.find('.js-scrollGradient_content').get(0).offsetHeight;
        const containerHeight = $container.get(0).offsetHeight;
        const containerScrolled = $container.get(0).scrollTop;

        if (containerHeight + containerScrolled < contentHeight) {
          $gradient.show();
        } else {
          $gradient.hide();
        }
      }

      $container.on('scroll', checkScroll);
      $(window).on('resize', checkScroll);
      checkScroll();
    });
  }
}
