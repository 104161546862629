export default {
  init() {
    const selector = {
      BUTTON: ".productsFilterGroup_collapseButton",
      CONTAINER: ".productsFilterGroup"
    };

    const className = {
      COLLAPSED: "productsFilterGroup-collapsed"
    };

    const $buttons = $(selector.BUTTON);

    $buttons.each((_, button) => {
      const $button = $(button);
      const $container = $button.closest(selector.CONTAINER);

      $button.on("click", event => $container.toggleClass(className.COLLAPSED));
    });
  }
};
