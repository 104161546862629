export default {
  init() {
    const selector = {
      MODAL: '.authModal',
      VISIBLE: '.authModal-visible',
      CLOSEBUTTON: '.authModal_close',
      USER_LANGUAGE_FIELD: '.js-userLanguageField',
    };

    const classname = {
      VISIBLE: 'authModal-visible',
      BODY_WITH_MODAL: 'modal-visible',
    };

    function closeActiveModal() {
      const $modal = $modals.filter(selector.VISIBLE).eq(0);
      if ($modal.length) {
        $modal.removeClass(classname.VISIBLE);
        $(document.body).removeClass(classname.BODY_WITH_MODAL);
        // TODO: a bit too tightly coupled?
        $modal.find('.ajaxForm_inputs').show();
        $modal.find('.ajaxForm_success').hide();
        $modal.find('input:disabled').removeAttr('disabled');
      }
    }

    function openModal(modal) {
      const $modal = $(modal);
      $modal.addClass(classname.VISIBLE);
      $(document.body).addClass(classname.BODY_WITH_MODAL);

      // get geo IP and update the language field
      getGeoIpaAndUpdateLanguage();
    }

    function getGeoIpaAndUpdateLanguage() {
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: '/api/geoip',
        success: data => {
          if (data.country) {
            if (data.country == 'EE') {
              $(selector.USER_LANGUAGE_FIELD).val('et').change();
            } else if (data.country == 'LT') {
              $(selector.USER_LANGUAGE_FIELD).val('lt').change();
            } else {
              $(selector.USER_LANGUAGE_FIELD).val('en').change();
            }
          }
        },
      });
    };

    function checkHash() {
      const hash = window.location.hash;

      const newActiveModal = $modals.filter(hash);

      closeActiveModal();

      if (newActiveModal.length > 0) {
        openModal(newActiveModal[0]);
      }
    }

    const $modals = $(selector.MODAL);

    checkHash();
    window.addEventListener('hashchange', checkHash);

    const $closeButtons = $(selector.CLOSEBUTTON);
    const $loginButton = $('.js-loginSubmit');
    const $signUpButton = $('.js-signupSubmit');

    $closeButtons.on('click', () => {
      window.location.hash = '';
    });

    $loginButton.on('click', () => {
      document.eventLogin();
    });

    if (window.location.search.indexOf('?result=ok') !== -1) {
      $(`${window.location.hash} .ajaxForm_inputs`).hide();
      $(`${window.location.hash} .ajaxForm_success`).show();
    }

    if (window.location.search.indexOf('?registerSuccess=true') === 0) {
      $('.authModal_body-login .ajaxForm_inputs').hide();
      $('.authModal_body-login .authModal_heading').hide();
      $('.authModal_body-login .ajaxForm_success').css('display', 'block');

      document.eventSignUp();
    }
  },
};
