import counter from './counter';

export default {
  page: 1,
  apiUrl: '/api/products/tender-items',
  selector: {
    PAGINATION: '#tendersPagination',
    LIST: '#tendersList',
    VIEW_SWITCHER_LIST: '#tenderView_list',
    CARD: '.productCard',
    EMPTY: '#tendersEmptyView',
    SEARCH_FORM: '#tenderSearchForm',
    FILTER_INPUT: '#tenderFilterInput',
    LOADING: '#tendersLoading',
    SORTING_DROPDOWN: '#tenderSorting'
  },
  updatePagination(pagination) {
    const {per_page, current_page, count, total, total_pages} = pagination;
    const seen = per_page * (current_page - 1) + count;
    const isLastPage = current_page >= total_pages;
    const $pagination = $(this.selector.PAGINATION);
    $('em', $pagination).eq(0).text(seen);
    $('em', $pagination).eq(1).text(total);
    $('button', $pagination).attr('disabled', isLastPage);
  },
  resetList() {
    const $list = $(this.selector.LIST);
    $list.empty();
    this.page = 1;
  },
  populateList(data) {
    const $list = $(this.selector.LIST);
    $(data).each((_, item) => {
      const $item = $(`<div class="productsGrid_item">${item.html}</div>`);
      if($(this.selector.VIEW_SWITCHER_LIST).is(':checked')) {
        $item.find(this.selector.CARD).addClass('productCard-row');
      }
      counter.init($item);
      $list.append($item);
    });
  },
  async fetchProducts() {
    $(this.selector.PAGINATION).hide();
    $(this.selector.LOADING).show();

    const requestUrl = new URL(this.apiUrl, window.location.href);
    requestUrl.searchParams.append('page', this.page);
    requestUrl.searchParams.append('q', $(this.selector.FILTER_INPUT).val());

    const sortValue = $(this.selector.SORTING_DROPDOWN).val();
    const [sortField, sortDirection] = sortValue.split('-');
    requestUrl.searchParams.append('sort', sortDirection);

    const {data, meta} = await (await fetch(requestUrl)).json();
    $(this.selector.LOADING).hide();
    
    this.updatePagination(meta.pagination);
    if (data.length > 0) {
      this.populateList(data);
      this.page += 1;
      $(this.selector.EMPTY).hide();
      $(this.selector.PAGINATION).show();
    } else {
      $(this.selector.EMPTY).show();
    }
  },
  initPaginationButton() {
    const $button = $('button', this.selector.PAGINATION);
    $button.on('click', () => {
      this.fetchProducts();
    });
  },
  initSearchForm() {
    const $form = $(this.selector.SEARCH_FORM);
    $form.on('submit', (event) => {
      event.preventDefault();
      this.resetList();
      this.fetchProducts();
    });
  },
  initSortingDropdown() {
    const $dropdown = $(this.selector.SORTING_DROPDOWN);
    $dropdown.on('change', () => {
      $dropdown.closest('form').submit();
    });
  },
  init() {
    this.resetList();
    this.fetchProducts();
    this.initPaginationButton();
    this.initSearchForm();
    this.initSortingDropdown();
  }
}
