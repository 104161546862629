export default {
  init() {
    const $slider = $(".js-overviewImageSlider");
    const $leftArrow = $(".js-imageSliderLeft");
    const $rightArrow = $(".js-imageSliderRight");
    const $dots = $(".js-imageSliderDots");

    $slider.slick({
      infinite: true,
      accessibility: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,
      arrows: true,
      nextArrow: $rightArrow,
      prevArrow: $leftArrow,
      dots: false,
      adaptiveHeight: false,
      fade: false,
      focusOnSelect: false,
      autoplay: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            dots: true,
            appendDots: $dots
          }
        }
      ]
    });

    const $sliderRecentlyViewed = $(".js-recentlyViewedSlider");
    const $leftArrowRecentlyViewed = $(".js-recentlyViewedLeft");
    const $rightArrowRecentlyViewed = $(".js-recentlyViewedRight");
    const $dotsRecentlyViewed = $(".js-recentlyViewedDots");

    $sliderRecentlyViewed.slick({
      infinite: true,
      accessibility: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      rows: 1,
      arrows: true,
      nextArrow: $rightArrowRecentlyViewed,
      prevArrow: $leftArrowRecentlyViewed,
      dots: true,
      appendDots: $dotsRecentlyViewed,
      adaptiveHeight: false,
      fade: false,
      focusOnSelect: false,
      autoplay: false,
      responsive: [
        {
          breakpoint: 992,
          settings: { slidesToShow: 3, slidesToScroll: 3 }
        },
        {
          breakpoint: 768,
          settings: "unslick"
        }
      ]
    });

    const $sliderRelatedProducts = $(".js-relatedProductsSlider");
    const $leftArrowRelatedProducts = $(".js-relatedProductsLeft");
    const $rightArrowRelatedProducts = $(".js-relatedProductsRight");
    const $dotsRelatedProducts = $(".js-relatedProductsDots");

    $sliderRelatedProducts.slick({
      infinite: true,
      accessibility: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      rows: 1,
      arrows: true,
      nextArrow: $rightArrowRelatedProducts,
      prevArrow: $leftArrowRelatedProducts,
      dots: true,
      appendDots: $dotsRelatedProducts,
      adaptiveHeight: false,
      fade: false,
      focusOnSelect: false,
      autoplay: false,
      responsive: [
        {
          breakpoint: 992,
          settings: { slidesToShow: 3, slidesToScroll: 3 }
        },
        {
          breakpoint: 768,
          settings: "unslick"
        }
      ]
    });

    const $sliderAddMoreItems = $(".js-addMoreItemsSlider");
    const $leftArrowAddMoreItems = $(".js-addMoreItemsSliderLeft");
    const $rightArrowAddMoreItems = $(".js-addMoreItemsSliderRight");
    const $dotsAddMoreItems = $(".js-addMoreItemsSliderDots");

    $sliderAddMoreItems.slick({
      infinite: true,
      accessibility: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      rows: 1,
      arrows: true,
      nextArrow: $rightArrowAddMoreItems,
      prevArrow: $leftArrowAddMoreItems,
      dots: true,
      appendDots: $dotsAddMoreItems,
      adaptiveHeight: false,
      fade: false,
      focusOnSelect: false,
      autoplay: false,
      responsive: [
        {
          breakpoint: 992,
          settings: { slidesToShow: 3, slidesToScroll: 3 }
        },
        {
          breakpoint: 768,
          settings: "unslick"
        }
      ]
    });
  }
};
