export default {
  init() {
    const selector = {
      INPUT: ".js-simpleDropdown",
      CONTAINER: ".simpleDropdown",
      VALUE: ".simpleDropdown_value",
      ITEMS: ".simpleDropdown_items li"
    };

    const classname = {
      OPEN: "simpleDropdown-open",
      ACTIVE: "simpleDropdown_items-active"
    };

    const dropdownWrappingHtml = select => `
        <div class="simpleDropdown_value">${select.selectedOptions[0].text}</div>
        <ol class="simpleDropdown_items">
          ${[...select.options].map(
            option => `
              <li class="${
                option.value === select.value ? classname.ACTIVE : ""
              }" data-value="${option.value}">${option.text}</li>
              `
          ).join('')}
        </ol>
      `;

    const dropdownSideClick = event => {
      if ($(event.target).closest(selector.CONTAINER).length === 0) {
        closeDropdown();
      }
    };

    const closeDropdown = () => {
      const $openDropdown = $(`.${classname.OPEN}`);

      $openDropdown.removeClass(classname.OPEN);
      $(window).off("click", dropdownSideClick);
    };

    const handleValueClick = event => {
      const $container = $(event.target).closest(selector.CONTAINER);

      if ($container.hasClass(classname.OPEN)) {
        closeDropdown();
      } else {
        $container.addClass(classname.OPEN);

        event.stopPropagation();
        $(window).on("click", dropdownSideClick);
      }
    };

    const handleItemClick = event => {
      const $item = $(event.target);
      const $container = $item.closest(selector.CONTAINER);
      const input = $container.find(selector.INPUT).get(0);

      $item
        .addClass(classname.ACTIVE)
        .siblings()
        .removeClass(classname.ACTIVE);
      input.value = event.target.dataset.value;
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      input.dispatchEvent(evt);
      $(selector.VALUE, $container).text(input.selectedOptions[0].text);
      closeDropdown();
    };

    $(selector.INPUT).each((_, input) => {
      const $input = $(input);

      $input.wrap('<div class="simpleDropdown"></div>');

      const $container = $input.parent();

      $container.append(dropdownWrappingHtml(input));

      const $value = $(selector.VALUE, $container);
      const $items = $(selector.ITEMS, $container);

      $value.on("click", handleValueClick);

      $items.on("click", handleItemClick);
    });
  }
};
