export default {
  init() {
    var $overviewDescriptionElement = $('.js-overviewDescription')
    var $overviewAddToCartElement = $('.js-overview_addToCart')

    $overviewAddToCartElement.on('click', function() {
      document.eventAddToCart();
    })

    if ($overviewDescriptionElement.length) {
      var $specsReadMoreButton = $('.js-overviewReadMoreButton')
      var $overviewDescriptionHeight = $overviewDescriptionElement[0].scrollHeight

      if ($overviewDescriptionHeight > 369) {
        $specsReadMoreButton.addClass('less');
        $specsReadMoreButton.css('display', 'block');
      }

      $specsReadMoreButton.click(function (e) {
        e.stopPropagation();

        if ($specsReadMoreButton.hasClass('less')) {
          $specsReadMoreButton.removeClass('less');
          $specsReadMoreButton.addClass('more');
          $specsReadMoreButton.text('Show less');

          $overviewDescriptionElement.animate({'height': $overviewDescriptionHeight});
        } else {
          $specsReadMoreButton.addClass('less');
          $specsReadMoreButton.removeClass('more');
          $specsReadMoreButton.text('Show more');
          $overviewDescriptionElement.animate({'height': '369px'});
        }
      });
    }
  }
};
