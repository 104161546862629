// Import our CSS
import styles from "../css/main.scss";

// import external dependencies
import $ from "jquery";
window.jQuery = $;
window.$ = $;
import "bootstrap";
import "slick-carousel/slick/slick.min";

// import local dependencies
import testimonials from "./components/testimonials";
testimonials.init();
import mainMenu from "./components/mainMenu";
mainMenu.init();
import mobileMenu from "./components/mobileMenu";
mobileMenu.init();
import newsSlider from "./components/newsSlider";
newsSlider.init();
import productSliders from "./components/productSliders";
productSliders.init();
import product from "./components/product";
product.init();
import counter from "./components/counter.js";
counter.init();
import filterCollapseButton from "./components/productsFIlterGroup_collapseButton";
filterCollapseButton.init();
import deselectableRadioList from "./components/forms/deselectableRadioList";
deselectableRadioList.init();
import rangeInput from "./components/forms/rangeInput";
rangeInput.init();
import textInput from "./components/forms/textInput";
textInput.init();
import fancyDropdown from "./components/forms/fancyDropdown";
fancyDropdown.init();
import simpleDropdown from "./components/forms/simpleDropdown";
simpleDropdown.init();
import productsFilter from "./components/productsFilter";
productsFilter.init();
import addToCartForm from "./components/addToCartForm";
addToCartForm.init();
import contactForm from "./components/contactForm";
contactForm.init();
import groupedSlider from "./components/groupedSlider";
groupedSlider.init();
import cart from "./components/cart";
cart.init();
import checkout from "./components/checkout";
checkout.init();
import registerForms from "./components/registerForms";
registerForms.init();
import ordersListItem from "./components/ordersListItem";
ordersListItem.init();
import addressForm from "./components/addressForm";
addressForm.init();
import news from "./components/news";
news.init();
import SidePanel from "./components/SidePanel";
$(".js-mobileMenuSidePanel").each(function(index, element) {
  new SidePanel(element, {
    $toggles: $(".js-mobileMenuToggle")
  });
});
import gridViewSwitcher from "./components/gridViewSwitcher";
gridViewSwitcher.init();
import tenderItemsList from "./components/tenderItemsList";
window.tenderItemsList = tenderItemsList;
import myAccountModal from "./components/myAccountModal";
myAccountModal.init();
import authModal from "./components/authModal";
authModal.init();
import ajaxForm from "./components/forms/ajaxForm";
ajaxForm.init();
import registerModalLink from "./components/registerModalLink";
registerModalLink.init();
import ordersList from "./components/ordersList";
ordersList.init();
import contactBlock from "./components/contactBlock";
contactBlock.init();
import checkTerms from "./components/checkTerms";
checkTerms.init();
import acceptTerms from "./components/acceptTerms";
acceptTerms.init();
import minicart from "./components/minicart";
minicart.init();
import search from "./components/search";
search.init();
import searchDropdown from "./components/searchDropdown";
searchDropdown.init();
import scrollGradient from "./components/scrollGradient";
scrollGradient.init();
import newsletterSubmit from "./components/newsletterSubmit";
newsletterSubmit.init();

// App main
const main = async () => {
  // Async load the vue module
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");
  // Create our vue instance
  const vm = new Vue({
    el: "#app",
    components: {
      confetti: () =>
        import(/* webpackChunkName: "confetti" */ "../vue/Confetti.vue")
    },
    data: {},
    methods: {},
    mounted() {}
  });

  return vm;
};

// Execute async function
main().then(vm => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
