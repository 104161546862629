export default {
  init() {
    const $slider = $(".js-clientSlider");
    const $leftArrow = $(".js-clientSliderLeft");
    const $rightArrow = $(".js-clientSliderRight");
    const $dots = $(".js-clientSliderDots");

    $slider.slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      rows: 1,
      arrows: true,
      nextArrow: $rightArrow,
      prevArrow: $leftArrow,
      dots: false,
      adaptiveHeight: false,
      fade: false,
      focusOnSelect: true,
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 6000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            appendDots: $dots
          }
        }
      ]
    });
  }
};
