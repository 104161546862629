export default {
  init() {
    $(".js-checkTerms").on("submit", function(event) {
      const $target = $($(this).data("target"));

      if ($target.is(":not(:checked)")) {
        $target.addClass("invalid");
        event.preventDefault();
      } else {
        $target.removeClass("invalid");
      }
    });
  }
};
