const clamp = (min, max, val) => val < min ? min : val > max ? max : val;

export default {
  init($base = $(document.body)) {
    const selector = {
      CONTAINER: '.rangeInput',
      TRACK: '.rangeInput_control',
      HANDLE: '.rangeInput_control_handle',
      START_HANDLE: '.rangeInput_control_handle-start',
      INPUT: '.rangeInput_values_input input',
      FINAL_INPUT: '.rangeInput_finalValue'
    }

    const variable = {
      LEFT: '--left',
      RIGHT: '--right'
    }

    const property = {
      PREVIOUS_VALUE: 'prevValue'
    }

    let currentHandle;

    const setContainerProperty = (container, propertyName, input) =>{
      const min = parseInt(input.getAttribute('min'), 10);
      const max = parseInt(input.getAttribute('max'));
      const value = clamp(min, max, parseInt(input.value));

      const track = container.querySelector(selector.TRACK);
      const handle = track.querySelector(selector.HANDLE);

      input.value = value; // what if bad value given

      const xFraction = (value - min) / (max - min);

      container.style.setProperty(propertyName, `${(track.getBoundingClientRect().width * xFraction) - handle.getBoundingClientRect().width / 2}px`);
    }

    const startDragging = (event) => {
      event.preventDefault();

      currentHandle = event.target;
      $(window).on('mousemove', handleMouseMove);
      $(window).on('mouseup blur', stopDragging)
    }

    const handleMouseMove = ({clientX}) => {
      const isStartHandle = currentHandle.matches(selector.START_HANDLE);
      const track = currentHandle.parentNode;
      const container = currentHandle.closest(selector.CONTAINER);
      const otherHandle = $(currentHandle).siblings(selector.HANDLE).get(0);
      const input = container.querySelectorAll(selector.INPUT)[isStartHandle ? 0 : 1];

      const trackBoundingRect = track.getBoundingClientRect();
      const otherHandleBoundingRect = otherHandle.getBoundingClientRect();
      const otherHandleX = otherHandleBoundingRect.x - trackBoundingRect.x;

      const varName = isStartHandle ? variable.LEFT : variable.RIGHT;
      const min = isStartHandle ? 0 : otherHandleX + otherHandleBoundingRect.width / 2;
      const max = isStartHandle ? otherHandleX + otherHandleBoundingRect.width / 2 : trackBoundingRect.width;

      const xValue = clamp(min, max, clientX - trackBoundingRect.x);

      container.style.setProperty(varName, `${xValue - otherHandleBoundingRect.width / 2}px`);

      const inputMin = parseInt(input.getAttribute('min'), 10);
      const inputMax = parseInt(input.getAttribute('max'), 10);
      const numberValue = clamp(inputMin, inputMax, Math.round(inputMin + (inputMax - inputMin) * xValue / trackBoundingRect.width)) || 0;

      input.value = numberValue;

      const finalInput = container.querySelector(selector.FINAL_INPUT);

      const currentValueParts = finalInput.value.split(':');

      if (isStartHandle) {
        finalInput.value = `${numberValue}:${currentValueParts[1]}`;
      } else {
        finalInput.value = `${currentValueParts[0]}:${numberValue}`;
      }
    }

    const stopDragging = () => {
      $(window).off('mousemove', handleMouseMove);
      $(window).off('mouseup blur', stopDragging);

      const isStartHandle = currentHandle.matches(selector.START_HANDLE);
      const container = currentHandle.closest(selector.CONTAINER);
      const input = container.querySelectorAll(selector.INPUT)[isStartHandle ? 0 : 1];
      $(input).trigger('change');
    }

    const onInputFocus = (event) => {
      $(event.target).prop(property.PREVIOUS_VALUE, event.target.value);
    };

    const onInputKeydown = (event) => {
      switch (event.which) {
        case 27: // escape
          event.target.value = $(event.target).prop(property.PREVIOUS_VALUE);
        case 13: // enter
          event.preventDefault();
          event.target.blur();
      }
    };

    const onInputChange = (event) => {
      const container = event.target.closest(selector.CONTAINER);
      const parsedValue = parseInt(event.target.value, 10);
      const isStartInput = $(event.target).attr('aria-label') === 'from';

      if (isNaN(parsedValue)) {
        event.target.value = $(event.target).prop(property.PREVIOUS_VALUE);
      } else {
        const otherInput = container.querySelectorAll(selector.INPUT)[isStartInput ? 1 : 0];
        const min = parseInt(isStartInput ? event.target.getAttribute('min') : otherInput.value, 10);
        const max = parseInt(isStartInput ? otherInput.value : event.target.getAttribute('max'), 10);
        const clampedValue = clamp(min, max, parsedValue);

        if (clampedValue !== parsedValue) {
          event.target.value = clampedValue;
        }
      }

      setContainerProperty(container, isStartInput ? variable.LEFT : variable.RIGHT, event.target);
    };

    const $containers = $(selector.CONTAINER, $base);

    $containers.each((_, container) => {
      const $handles = $(selector.HANDLE, container);
      const $inputs = $(selector.INPUT, container);

      setContainerProperty(container, variable.LEFT, $inputs.get(0));
      setContainerProperty(container, variable.RIGHT, $inputs.get(1));

      $handles.on('mousedown', startDragging);

      $inputs.on('focus', onInputFocus);

      $inputs.on('keydown', onInputKeydown)

      $inputs.on('change', onInputChange);
    });
  }
};
