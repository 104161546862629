export default {
  init() {
    const selector = {
      FORGOT_PWD_BUTTON: ".js-forgotPwdButton",
      LOGIN_BUTTON: ".js-loginButton",
      SIGNUP_BUTTON: ".js-signupButton",

      FORGOT_PWD_SECTION: ".js-forgotPwd",
      FORGOT_PWD_FORM: ".js-forgotPwdForm",
      FORGOT_PWD_MESSAGE: ".js-forgotPwdMessage",

      REGISTER_SECTION: ".js-registerSection",
      REGISTER_FORM_CONTAINER: ".register_formContainer",
      REGISTER_NAV_LINK: ".js-registerNavLink"
    };

    const $forgotPwdButton = $(selector.FORGOT_PWD_BUTTON);
    const $loginButton = $(selector.LOGIN_BUTTON);
    const $signupButton = $(selector.SIGNUP_BUTTON);

    const $forgotPwdSection = $(selector.FORGOT_PWD_SECTION);
    const $forgotPwdForm = $(selector.FORGOT_PWD_FORM);
    const $forgotPwdMessage = $(selector.FORGOT_PWD_MESSAGE);

    const $registerSection = $(selector.REGISTER_SECTION);
    const $registerFormContainer = $(selector.REGISTER_FORM_CONTAINER);
    const $registerNavLink = $(selector.REGISTER_NAV_LINK);

    $forgotPwdButton.click(function(e) {
      e.preventDefault();

      $registerSection.hide();

      $forgotPwdSection.show();

      window.history.pushState({}, '', '?tab=forgotPassword');
    });

    $loginButton.click(function(e) {
      e.preventDefault();

      $forgotPwdSection.hide();

      $registerSection.show();

      window.history.pushState({}, '', '?tab=login');
    });

    $signupButton.click(function(e) {
      e.preventDefault();

      $forgotPwdSection.hide();

      $registerSection.show();
      $("#register-login").hide();

      $registerNavLink.removeClass("cartFormNav_link-active");
      $('[name="register-signup"]').addClass("cartFormNav_link-active");

      $("#register-signup").show();

      window.history.pushState({}, '', '?tab=registerNewAccount');
    });

    $forgotPwdForm.submit(function(e) {
      e.preventDefault();

      $forgotPwdMessage.show();
      $forgotPwdForm.hide();
    });

    $registerNavLink.click(function(e) {
      e.preventDefault();

      $registerNavLink.removeClass("cartFormNav_link-active");
      $(this).addClass("cartFormNav_link-active");

      $registerFormContainer.hide();
      $("#" + $(this).attr("name")).show();

      let tab;

      switch ($(this).attr('name')) {
        case 'register-signup':
          tab = 'registerNewAccount';
        break;
        case 'register-login':
          tab = 'login';
        break;
      }

      window.history.pushState({}, '', `?tab=${tab}`);
    });

    const urlquery = new URLSearchParams(window.location.search);

    switch (urlquery.get('tab')) {
      case 'registerNewAccount':
        $signupButton.trigger('click');
      break;
      case 'forgotPassword':
        $forgotPwdButton.trigger('click');
      break;
      case 'login':
        $loginButton.trigger('click');
      break;
    }
  }
};
