import { debounce } from "throttle-debounce";
import counter from "./counter";

export default {
  init() {
    const selector = {
      PAGE_CONTAINER: ".js-searchPageResults",
      PRODUCTS_CONTAINER: ".js-searchPageProducts",
      ARTICLES_CONTAINER: ".js-searchPageArticles",
      NAV_PRODUCTS: ".js-searchNavProducts",
      NAV_ARTICLES: ".js-searchNavArticles",
      SEARCH_QUERY: ".js-searchPageQuery",
      PRODUCTS_COUNT: ".js-searchProductsCount",
      ARTICLES_COUNT: ".js-searchArticlesCount",
      PRODUCT_FILTER: ".js-searchProductsFilter"
    };

    const apiUrl = "/api/search";

    const $searchNavProducts = $(selector.NAV_PRODUCTS);
    const $searchNavArticles = $(selector.NAV_ARTICLES);

    const $productsContainer = $(selector.PRODUCTS_CONTAINER);
    const $articlesContainer = $(selector.ARTICLES_CONTAINER);

    const $productsCount = $(selector.PRODUCTS_COUNT);
    const $articlesCount = $(selector.ARTICLES_COUNT);

    const $productFilterBlock = $(selector.PRODUCT_FILTER);

    const showProducts = () => {
      $articlesContainer.removeClass("search_articlesResults-open");
      $productsContainer.addClass("search_productsResults-open");
      if ($searchNavArticles.hasClass("cartFormNav_link-active")) {
        $searchNavArticles.removeClass("cartFormNav_link-active");
      }
      $searchNavProducts.addClass("cartFormNav_link-active");
      $productFilterBlock.show();
    };

    const showArticles = () => {
      $productsContainer.removeClass("search_productsResults-open");
      $articlesContainer.addClass("search_articlesResults-open");

      if ($searchNavProducts.hasClass("cartFormNav_link-active")) {
        $searchNavProducts.removeClass("cartFormNav_link-active");
      }
      $searchNavArticles.addClass("cartFormNav_link-active");

      $productFilterBlock.hide();
    };

    $searchNavArticles.on("click", function(e) {
      e.preventDefault();
      if ($productsContainer.hasClass("search_productsResults-open")) {
        showArticles();
      }
    });

    $searchNavProducts.on("click", function(e) {
      e.preventDefault();
      if ($articlesContainer.hasClass("search_articlesResults-open")) {
        showProducts();
      }
    });

    const articlesResultsHtml = articles => `
        ${
          articles === undefined
            ? `<div class="col">No articles found</div>`
            : articles
                .map(
                  (item, index) =>
                    `<div class="col-md-4 search_item" key="${index}">${item}</div>`
                )
                .join("")
        }
    `;

    async function fetchPageResults(query) {
      const requestUrl = new URL(apiUrl, window.location.href);
      requestUrl.searchParams.append("q", query);
      requestUrl.searchParams.append("show", "grid");

      const { data, meta } = await (await fetch(requestUrl)).json();

      $searchNavProducts.css("flex-direction", "row");
      $searchNavArticles.css("flex-direction", "row");
      $productsCount.text(`(${meta.pagination.total})`);
      $articlesCount.text(
        meta.articles === undefined ? `(0)` : `(${meta.articles.length})`
      );
      $articlesContainer.append(articlesResultsHtml(meta.articles));

      if (!data.length) showArticles();
      else showProducts();
    }

    if ($articlesContainer.length) {
      const urlquery = new URLSearchParams(window.location.search);

      const $searchQuery = $(selector.SEARCH_QUERY);
      $searchQuery.text(urlquery.get("q"));

      fetchPageResults(urlquery.get("q"));
    }
  }
};
