export default {
  init() {
    const selector = {
      OPEN: '.js-accountOpen',
      CLOSE: '.js-accountClose',
      MODAL: '.myAccountModal'
    };

    const classname = {
      MY_ACCOUNT_OPEN: 'myAccountOpen'
    }

    function handleSideclick(event) {
      if ($(event.target).closest(selector.MODAL).length === 0 && $(event.target).closest(selector.OPEN).length === 0) {
        closeModal();
      }
    }

    function closeModal() {
      $(selector.MODAL).hide();
      $(window).off('click', handleSideclick);
      $(document.body).removeClass(classname.MY_ACCOUNT_OPEN);
    }

    $(selector.OPEN).on('click', (event) => {
      event.preventDefault();
      $(selector.MODAL).show();
      $(window).on('click', handleSideclick);
      $(document.body).addClass(classname.MY_ACCOUNT_OPEN);
    });

    $(selector.CLOSE).on('click', event => {
      closeModal();
    });
  }
}