import minicart from "./minicart";

export default {
  init() {
    const $promoButton = $(".js-cartPromoButton");
    const $promoInput = $(".js-cartPromoCode");

    $('body').on('click', '.js-cartPromoButton', function(e) {
      e.preventDefault();
      $('.js-cartPromoCode').toggle()
      $('.js-cartPromoInput').focus()
    });

    var getAndShowCart = () => {
      $.ajax({
        type: "GET",
        dataType: "json",
        url: "/api/cart",
        success: data => {
          console.log(data);
          $(".js-headerCartButtonText").text(
            "Cart " + "(" + data.meta.totalQty + ")"
          );
          $(".js-minicart").remove();
          $("body").prepend(data.html);
          $(".js-minicart").addClass("minicart-visible");
          minicart.init(); // Init minicart again since the cart has changed
        }
      });
    };

    $(function() {
      $(".products").on("productsloaded", () => {

        $(".js-addToCartForm").each((index, el) => {
          var $addToCartBtn = $(el).find('.js-addToCartBtn');
          $addToCartBtn.off("click"); // remove duplicates on successive product load (using load more btn)
          $addToCartBtn.on("click", e => {
            e.preventDefault();

            // Trigger event
            document.eventAddToCart();

            var purchasable = $(el).find('.js-addToCartBtn').val();
            var qty = $(el).find('.js-productCounter').val();

            $.ajax({
              type: "POST",
              dataType: "json",
              headers: {
                "X-CSRF-Token":
                  "wTUlA7c8Nf10R1Jq4ThXvbHWvhvKfWRMyta8dh1q6n3kimf47HINXziwZ0pwQoMB-vEQm3IOmDGXSv8EK6j-LvNBA7wbQdLTkEY1pcl94UExMTOEwOtXMQ0QOquruUvoPCtHtYDaOyfswfWmgwafz6I0y_dbbTQEm2HhevM9ByhT6WNNU7dqVY-b53KM3-PuaQowetr6TyVdhop5vMDpc7kHSIi2kMlzqQzBkxoQ6AgBOVfR7J_lFSg9T1rwmDDo5JrnyAJ5-nnXdmnWBi-Ek-OJWAijenJK5VJvzysPFg6LXTDX2KTNLYQnDAC4lYQvUiWoKdL5MK_fCkEoRIRVchZz5TXDxnL6ET2gVKBznTxInZsaw3U133oj5OKnI1HBr0zVd1IIBuKjjzQEOCQPypKJe9wESnfQ4-5aQ42juvHKVPGVkGuDsz8HUWPxCJMJxXNdQB-bIHUK-CUX262UJdvsm6IedgNJ7MN_WXm0812N881D8Sl4z4O-kAfMPJP-KiS-W2JteJij-51xcQQrLYf5aa2h66mbSEmwMeVYK45IWLbK2rFpbQ=="
              },
              url: "/api/cart",
              data: {
                action: "commerce/cart/update-cart",
                purchasableId: purchasable,
                note: "from ajax",
                qty: qty
              },
              success: function(data) {
                console.log(data);
                getAndShowCart();
              }
            });
          });
        });
      });
    });
  }
};
