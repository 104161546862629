export default {
  init() {
    const selector = {
      FORM: '.js-ajaxForm',
      INPUTS: '.ajaxForm_inputs',
      SUCCESS: '.ajaxForm_success',
      SUBMITBUTTON: 'input[type=submit]'
    };

    const $forms = $(selector.FORM);

    $forms.on('submit', async (event) => {
      event.preventDefault();

      const $form = $(event.target);

      $form.find(selector.SUBMITBUTTON).attr('disabled', 'disabled');

      const serialized = $form.serializeArray().reduce((acc, curr) => {acc[curr.name] = curr.value; return acc;}, {});

      const response = await fetch(event.target.getAttribute('action'), {
        method: event.target.method,
        body: JSON.stringify(serialized)
      });

      if (response.ok) {
        $form.find(selector.INPUTS).hide();
        $form.find(selector.SUCCESS).show();
      }

      $form.find(selector.SUBMITBUTTON).removeAttr('disabled');
    });
  }
};
