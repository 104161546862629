export default {
  init() {
    const selector = {
      MENU: '.js-mainMenu',
      DROPDOWN: '.js-mainMenu_dropdown',
      DROPDOWN_TOGGLE: '.js-mainMenu_dropdownToggle',
      SUBMENU_DROPDOWN: '.js-mainMenu_submenuDropdown',
      SUBSUBMENU_DROPDOWN: '.js-mainMenu_subsubmenuDropdown',
      SUBMENU_DROPDOWN_TOGGLE: '.js-mainMenu_submenuDropdownToggle',
      SUBSUBMENU_DROPDOWN_TOGGLE: '.js-mainMenu_subsubmenuDropdownToggle',
      MENU_ITEM_TRIANGLE: '.js-mainMenu_itemTriangle',
      BANNER: '.mainMenu_bannerContainer',
      dataset: {
        MENU_ITEM_ID: 'menuItemId',
        MENU_DROPDOWN_ID: 'menuDropdownId',
        SUBMENU_ITEM_ID: 'submenuItemId',
        SUBMENU_DROPDOWN_ID: 'submenuDropdownId',
      }
    }

    const className = {
      SHOW: 'st-show',
      ACTIVE: 'st-active'
    }

    function timer(length) {
      let timer = null;

      function start(callback) {
        if (timer) {
          window.clearTimeout(timer);
        }
        timer = window.setTimeout(function () {
          callback();
          timer = null;
        }, length);
      }

      function stop() {
        if (timer) {
          window.clearTimeout(timer);
          timer = null;
        }
      }

      return {
        start: start,
        stop: stop,
      }
    }

    const $menu = $(selector.MENU);
    const $dropdowns = $menu.find(selector.DROPDOWN);
    const $submenuDropdowns = $menu.find(selector.SUBMENU_DROPDOWN);
    const $subsubmenuDropdowns = $menu.find(selector.SUBSUBMENU_DROPDOWN);
    const $toggles = $menu.find(selector.DROPDOWN_TOGGLE);
    const $submenuToggles = $menu.find(selector.SUBMENU_DROPDOWN_TOGGLE);
    const $subsubmenuToggles = $menu.find(selector.SUBSUBMENU_DROPDOWN_TOGGLE);
    const $banner = $menu.find(selector.BANNER);

    function closeAllSecondary() {
      $menu.find(selector.SUBMENU_DROPDOWN + '.' + className.SHOW).removeClass(className.SHOW);
      $menu.find(selector.SUBMENU_DROPDOWN_TOGGLE + '.' + className.ACTIVE).removeClass(className.ACTIVE);
    }

    function hideBanner() {
      $banner.hide();
    }

    function showBanner() {
      $banner.show();
    }

    function closeAllTertiary() {
      $menu.find(selector.SUBSUBMENU_DROPDOWN + '.' + className.SHOW).removeClass(className.SHOW);
      $menu.find(selector.SUBSUBMENU_DROPDOWN_TOGGLE + '.' + className.ACTIVE).removeClass(className.ACTIVE);
      showBanner();
    }

    function closeAll() {
      closeAllSecondary();
      closeAllTertiary();
      $menu.find(selector.DROPDOWN + '.' + className.SHOW).removeClass(className.SHOW);
      $menu.find(selector.MENU_ITEM_TRIANGLE + '.' + className.SHOW).removeClass(className.SHOW);
    }


    $toggles.each(function (index, toggle) {
      const $toggle = $(toggle);
      const $toggleId = $toggle.get(0).dataset[selector.dataset.MENU_ITEM_ID];
      const $dropdown = $dropdowns.filter(function (index, dropdown) {
        return dropdown.dataset[selector.dataset.MENU_DROPDOWN_ID] === $toggleId;
      })
      const $triangle = $toggle.parent().find(selector.MENU_ITEM_TRIANGLE);
      const gracePeriod = timer(500);

      $toggle.on('mouseover', function (e) {
        gracePeriod.stop();
        closeAll();
        $dropdown.addClass(className.SHOW);
        $triangle.addClass(className.SHOW);
      })

      $dropdown.on('mouseover', function (e) {
        gracePeriod.stop();
      })

      $toggle.on('mouseleave', function (e) {
        gracePeriod.start(function () {
          $dropdown.removeClass(className.SHOW);
          $triangle.removeClass(className.SHOW);
        });
      })

      $dropdown.on('mouseleave', function (e) {
        closeAllTertiary();
        gracePeriod.start(function () {
          $dropdown.removeClass(className.SHOW);
          $triangle.removeClass(className.SHOW);
        });
      })

      $(document).on('click', function (e) {
        if (!$(e.target).closest(selector.MENU).length) {
          closeAll();
        }
      });

    });


    $submenuToggles.each(function (index, toggle) {
      const $submenuToggle = $(toggle);
      const $submenuToggleId = $submenuToggle.get(0).dataset[selector.dataset.SUBMENU_ITEM_ID];
      const $submenuDropdown = $submenuDropdowns.filter(function (index, dropdown) {
        return dropdown.dataset[selector.dataset.SUBMENU_DROPDOWN_ID] === $submenuToggleId;
      })

      $submenuToggle.on('mouseover', function (e) {
        closeAllSecondary();
        closeAllTertiary();
        $submenuDropdown.addClass(className.SHOW);
        $submenuToggle.addClass(className.ACTIVE);
      })

    })

    $subsubmenuToggles.each(function(index, toggle) {
      const $submenuToggle = $(toggle);
      const $submenuToggleId = $submenuToggle.get(0).dataset[selector.dataset.SUBMENU_ITEM_ID];
      const $submenuDropdown = $subsubmenuDropdowns.filter(function (index, dropdown) {
        return dropdown.dataset[selector.dataset.SUBMENU_DROPDOWN_ID] === $submenuToggleId;
      })

      $submenuToggle.on('mouseover', function (e) {
        closeAllTertiary();
        if ($submenuDropdown.find('li').length) {
          $submenuDropdown.addClass(className.SHOW);
          hideBanner();
        }
        $submenuToggle.addClass(className.ACTIVE);
      })

    })
  }
}
