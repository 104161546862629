import ordersListItem from "./ordersListItem";

export default {
  page: 1,
  apiUrl: '/api/orders',
  selector: {
    LIST: '.js-ordersList',
    PAGINATION: '.ordersList .pagination',
    LOADING: '.ordersList .loadingIndicator',
    SORTING_DROPDOWN: '#orderSorting',
    EMPTY: '#ordersEmptyView'
  },
  resetList() {
    const $list = $(this.selector.LIST);
    $list.empty();
    this.page = 1;
  },
  async fetchOrders() {
    $(this.selector.PAGINATION).hide();
    $(this.selector.LOADING).show();

    const requestUrl = new URL(this.apiUrl, window.location.href);
    requestUrl.searchParams.append('page', this.page);

    const sortValue = $(this.selector.SORTING_DROPDOWN).val();
    const sortField = sortValue.split('-')[0];
    const sortDirection = sortValue.split('-')[1];
    requestUrl.searchParams.append('sort', sortDirection);
    requestUrl.searchParams.append('by', sortField);

    const {data, meta} = await (await fetch(requestUrl)).json();
    $(this.selector.LOADING).hide();
    
    this.updatePagination(meta.pagination);
    if (data.length > 0) {
      this.populateList(data);
      this.page += 1;
      $(this.selector.EMPTY).hide();
      $(this.selector.PAGINATION).show();
    } else {
      $(this.selector.EMPTY).show();
    }
  },
  populateList(data) {
    const $list = $(this.selector.LIST);
    $(data).each((_, item) => {
      const $item = $(item.html);
      ordersListItem.init($item);
      $list.append($item);
    });
  },
  updatePagination(pagination) {
    const {per_page, current_page, count, total, total_pages} = pagination;
    const seen = per_page * (current_page - 1) + count;
    const isLastPage = current_page >= total_pages;
    const $pagination = $(this.selector.PAGINATION);
    $('em', $pagination).eq(0).text(seen);
    $('em', $pagination).eq(1).text(total);
    $('button', $pagination).attr('disabled', isLastPage).toggle(!isLastPage);
  },
  initPagination() {
    const $button = $('button', this.selector.PAGINATION);
    $button.on('click', () => {
      this.fetchOrders();
    });
  },
  initSortingDropdown() {
    const $dropdown = $(this.selector.SORTING_DROPDOWN);
    $dropdown.on('change', () => {
      this.resetList();
      this.fetchOrders();
    });
  },
  init() {
    if ($(this.selector.LIST).length) {
      this.resetList();
      this.fetchOrders();
      this.initPagination();
      this.initSortingDropdown();
    }
  }
};
