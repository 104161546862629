export default {
  init() {
    const selector = {
      LINK: '.js-registerModal-link',
      TAB: '.registerModal_tab'
    };

    const classname = {
      LINK_ACTIVE: 'cartFormNav_link-active',
      TAB_ACTIVE: 'registerModal_tab-active'
    };

    const $links = $(selector.LINK);
    const $tabs = $(selector.TAB);

    $links.on('click', (event) => {
      event.preventDefault();

      const { target } = event;
      const $link = $(target);

      const index = $link.index();
      $links.removeClass(classname.LINK_ACTIVE);
      $link.addClass(classname.LINK_ACTIVE);

      $tabs.removeClass(classname.TAB_ACTIVE);
      $tabs.eq(index).addClass(classname.TAB_ACTIVE);
    });

    const urlquery = new URLSearchParams(window.location.search);

    switch (urlquery.get('tab')) {
      case 'personal':
        $links.eq(1).trigger('click');
      break;
      case 'business':
        $links.eq(0).trigger('click');
      break;
    };
  }
};
