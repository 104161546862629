export default {
  init() {
    const selector = {
      MENU: '.js-mobileMenu',
      PRIMARY_MENU: '.js-mobileMenu-primary',
      SECONDARY_MENU: '.js-mobileMenu-secondary',
      SUBMENU_CONTAINER: '.js-mobileMenu-submenuContainer',
      MENU_TOGGLE_SUBMENU: '.js-mobileMenu-toggleSubmenu',
      BACK_BUTTON: '.js-mobileMenu-back',
      dataset: {
        MENU_ITEM_ID: 'menuItemId',
        MENU_CONTAINER_ID: 'menuContainerId',
        MENU_PARENT_ITEM_ID: 'menuParentItemId',
      }
    }

    const className = {
      SHOW: 'st-show',
      HIDE: 'st-hide',
    }

    let state = {
      parent_container_id: null,
    }

    const $menu = $(selector.MENU);
    const $primaryMenu = $menu.find(selector.PRIMARY_MENU);
    const $secondaryMenu = $menu.find(selector.SECONDARY_MENU);
    const $submenuContainers = $menu.find(selector.SUBMENU_CONTAINER);
    const $toggles = $menu.find(selector.MENU_TOGGLE_SUBMENU);
    const $backButton = $menu.find(selector.BACK_BUTTON);

    function hidePrimary() {
      $primaryMenu.addClass(className.HIDE);
      $secondaryMenu.addClass(className.SHOW);
    }

    function showPrimary() {
      $primaryMenu.removeClass(className.HIDE);
      $secondaryMenu.removeClass(className.SHOW);
      state.parent_container_id = null;
    }

    function hideSubmenuContainers() {
      $submenuContainers.each(function (index, container) {
        const $container = $(container)
        $container.removeClass(className.SHOW)
      })
    }

    function showSubmenuContainer(toggleId) {
      const $container = $submenuContainers.filter(function (index, container) {
        return container.dataset[selector.dataset.MENU_CONTAINER_ID] === toggleId;
      })

      if ($container.data(selector.dataset.MENU_PARENT_ITEM_ID)) {
        state.parent_container_id = String($container.data(selector.dataset.MENU_PARENT_ITEM_ID));
      } else {
        state.parent_container_id = null;
      }
      $container.addClass(className.SHOW)
    }

    $toggles.each(function (index, toggle) {
      const $toggle = $(toggle);
      const toggleId = $toggle.get(0).dataset[selector.dataset.MENU_ITEM_ID];

      $toggle.on('click', function (e) {
        hidePrimary();
        hideSubmenuContainers();
        showSubmenuContainer(toggleId)
      })
    })

    $backButton.on('click', function (e) {
      hideSubmenuContainers();

      if (state.parent_container_id) {
        showSubmenuContainer(state.parent_container_id);
      } else {
        showPrimary();
      }
    })
  }
}
