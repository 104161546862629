export default {
  init() {
    const selector = {
      CONTAINER: '.js-gridViewSwitcher',
      ITEM: '.productCard',
    };

    const $containers = $(selector.CONTAINER);

    $containers.each((_, container) => {
      const $container = $(container);

      $container.on('change', (event) => {
        const $t = $(event.target);
        const $grid = $(`#${$container.data('target-id')}`);

        if ($t.val() === 'list') {
          $grid.removeClass('productsGrid-grid productsGrid-grid-fourths');
          $grid.addClass('productsGrid-list');
          $grid.find(selector.ITEM).addClass('productCard-row');
        } else {
          $grid.removeClass('productsGrid-list');
          $grid.addClass($container.data('grid-classname') || 'productsGrid-grid');
          $grid.find(selector.ITEM).removeClass('productCard-row');
        }
      });
    })
  }
};
