export default {
  init() {
    const $slider = $(".js-groupedSlider");
    const $leftArrow = $(".js-groupedSliderLeft");
    const $rightArrow = $(".js-groupedSliderRight");

    $slider.slick({
      infinite: true,
      accessibility: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      rows: 1,
      arrows: true,
      nextArrow: $rightArrow,
      prevArrow: $leftArrow,
      dots: false,
      adaptiveHeight: false,
      fade: false,
      focusOnSelect: false,
      autoplay: false,
      responsive: [
        {
          breakpoint: 768,
          settings: "unslick"
        }
      ]
    });

    const $buttons = $('.groupedSlider_menuLink');
    const $cards = $('.groupedSlider_card');

    $buttons.on('click', (event) => {
      const $target = $(event.target);

      if(!$target.hasClass('active')) {
        $target.siblings().removeClass('active');
        $target.addClass('active');
        const targetDataId = $target.data('category-id');
        let targetId;
        $slider.slick('slickUnfilter');
        if (targetDataId) {
          targetId = targetDataId.toString();
          $slider.slick('slickFilter', (_, card) => {
            const $card = $(card);
            const ids = $card.data('category-ids').split('|').filter(id => !!id);
            const returnValue = ids.includes(targetId);
            return returnValue;
          });
        }
      }
    });    
  }
};
