export default {
  init() {
    $("form.addToCartForm").submit(function(e) {
      e.preventDefault();
      $.ajax({
        type: "POST",
        dataType: "json",
        headers: {
          "X-CSRF-Token": "{{ craft.app.request.csrfToken }}"
        },
        url: "",
        data: {
          action: e.target.action.value,
          purchasableId: e.target.purchasableId.value,
          note: "from ajax"
        },
        success: function(data) {
          console.log(data);
        }
      });
    });
  }
};
