export default {
  init() {
    const $slider = $(".js-newsSlider");
    const $leftArrow = $(".js-newsSliderLeft");
    const $rightArrow = $(".js-newsSliderRight");
    const $dots = $(".js-newsSliderDots");
    const $relatedNewsCount = $(".js-relatedNewsCount");

    const relatedNewsTotal = $relatedNewsCount.val();

    $slider.slick({
      infinite: true,
      accessibility: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      rows: 1,
      arrows: true,
      nextArrow: $rightArrow,
      prevArrow: $leftArrow,
      dots: true,
      appendDots: $dots,
      adaptiveHeight: true,
      fade: false,
      focusOnSelect: false,
      autoplay: false,
      responsive: [
        {
          breakpoint: 768,
          settings: "unslick"
        }
      ]
    });

    if (relatedNewsTotal < 3) $slider.slick("unslick");
  }
};
