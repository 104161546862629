import {debounce} from 'throttle-debounce';
import counter from './counter';
import fancyDropdown from './forms/fancyDropdown';
import rangeInput from './forms/rangeInput';

export default {
  init() {
    const selector = {
      FORM: '.js-productsFilter',
      RESULT_CONTAINER: '.js-filterResultsContent',
      PAGINATION: '#productsFilterPagination',
      LOADING: '#productsFilterLoadingIndicator',
      EMPTY: '#productsFilterEmptyView',
      FORM: '#productsFilter',
      VIEW_SWITCHER_LIST: '#productsList_list',
      CARD: '.productCard',
      OPEN_BUTTON: '.js-productsFilterOpenButton',
      CLOSE_BUTTON: '.js-productsFilterCloseButton',
      SUBMIT_BUTTON: '.js-productsFilterSubmitButton',
      RESET_BUTTON: '.js-productsFilterResetButton',
      PRODUCTS_COUNT: '.js-searchProductsCount',
      PRODUCTS_FILTER_GROUP: '.js-productsFilterGroup',
      SELECT_CONTAINER: '.fancyDropdown',
      SELECT_DEFAULT: '.js-filterSelect-default',
      SELECT_CURRENT_TITLE: '.js-fancyDropdown_current',
      SELECT_ITEMS: '.js-fancyDropdown_items',
      SELECT_LIST: '.js-fancyDropdown_list',
      SELECT_LABEL: '.js-fancyDropdown_label',
      FILTER_TAGS: '.js-filterTags',
      FILTER_TAG: '.js-filterTag',
      FILTER_TAG_SWAP: '.js-filterTag.swap',
      FILTER_TAG_FIRST: '.js-filterTagFirst',
      FILTER_TAGS_CLOSE: '.js-filterTagsClose',
      FILTER_TAGS_COUNT: '.js-filterTagsResultCount',
      RANGE: '.js-rangeInput',
      RANGE_INPUT: '.js-rangeInput_input',
      RANGE_FINAL: '.js-rangeInput_final',
    };

    let page = 1;

    let cancelController;

    const urlquery = new URLSearchParams(window.location.search);
    const searchQuery = urlquery.get('q');

    function updatePagination(pagination) {
      const { per_page, current_page, count, total, total_pages } = pagination;
      const seen = per_page * (current_page - 1) + count;
      const isLastPage = current_page >= total_pages;
      const $pagination = $(selector.PAGINATION);
      $('em', $pagination).eq(0).text(seen);
      $('em', $pagination).eq(1).text(total);
      $('button', $pagination).attr('disabled', isLastPage);
      $(selector.FILTER_TAGS_COUNT).text(total);
    };

    function populateList(data) {

      const $list = $(selector.RESULT_CONTAINER);
      $(data).each((_, item) => {
        const $item = $(`<div class="productsGrid_item">${item.html}</div>`);
        if ($(selector.VIEW_SWITCHER_LIST).is(':checked')) {
          $item.find(selector.CARD).addClass('productCard-row');
        }
        counter.init($item);
        $list.append($item);
      });

      // Create event for when products are loaded
      const event = new Event('productsloaded');
      document.querySelector('.products').dispatchEvent(event);
    }

    function resetList() {
      const $list = $(selector.RESULT_CONTAINER);
      $list.empty();
      page = 1;
    }

    function updateSearchResultsCount(count) {
      $(selector.PRODUCTS_COUNT).text(`(${count})`);
    }

    function renderCheckbox(inputName, value, title, count) {
      const $checkbox = $($('#filter-styled-checkbox-template').html());

      $checkbox.find('input').attr('name', `${inputName}[]`);
      $checkbox.find('input').attr('value', value);

      if (count) {
        title = title + ` <span class="quantityIndicator">(${count})</span>`;
      }

      $checkbox.find('span > span').html(`${title}`);

      return $checkbox;
    }

    function renderSelect(inputName, options) {
      const optionsHtml = options.map(([inputValue, count]) => {
        return `<option value="${inputValue}">${inputValue} (${count})</option>`;
      });

      const selectHtml = `
        <select name="${inputName}" class="js-fancyDropdown">
          <option value="">All</option>
          ${optionsHtml.join('')}
        </select>
      `;

      return $(selectHtml);
    }

    function updateFilters(filters) {
      $('.productsFilterGroup section section .quantityIndicator').text('(0)');

      const filtersItems = Object.entries(filters);

      filtersItems.forEach(([inputName, filterObject]) => {
        if (filterObject instanceof Array || !filterObject.data) {
          // filter object should not be an array - current implementation passes it as such when it's empty/hidden
        } else {
          const $existingFilterBlock = $(`[data-field="${inputName}"]`);

          if ($existingFilterBlock.length) {
            // UPDATE values in existing filter block instead of rendering a new one
            switch (filterObject.type) {
              case 'list': {
                const options = Object.entries(filterObject.data);
                const currentCountText = $existingFilterBlock.find('h5 span').text();
                const currentCount = parseInt(currentCountText.substr(1, currentCountText.length - 1), 10);
                if (currentCount < options.length) {
                  $existingFilterBlock.find('h5 span').text(`(${options.length})`);
                }

                options.forEach(([value, { title, count }]) => {
                  // each _new_ option
                  const $existingOption = $existingFilterBlock.find(
                      `input[value="${value}"][name="${inputName}[]"]`).parent();

                  if ($existingOption.length) {
                    // if it's rendered, just update the count
                    $existingOption.find('span > span.quantityIndicator').text(`(${count})`);
                  } else {
                    // otherwise render a new checkbox
                    const $checkbox = renderCheckbox(inputName, value, title, count);
                    $checkbox.find('input').attr('checked', $existingOption.find('input').is(':checked'));
                    $existingOption.replaceWith($checkbox);
                  }
                });

                break;
              }
              case 'option': {
                const options = Object.entries(filterObject.data);
                const currentCountText = $existingFilterBlock.find('h5 span').text();
                const currentCount = parseInt(currentCountText.substr(1, currentCountText.length - 1), 10);
                if (currentCount < options.length) {
                  $existingFilterBlock.find('h5 span').text(`(${options.length})`);
                }
                $existingFilterBlock.find('li').each((index, li) => {
                  // each rendered list item in the select (except the empty one)
                  if ($(li).data('value') !== '') {
                    // just update the count
                    const newCount = filterObject.data[$(li).data('value')] || 0;

                    $(li).text($(li).text().replace(/\(\d+\)/, `(${newCount})`));
                  }
                });

                break;
              }
              case 'range': {
                // nothing to update for range
                break;
              }
              default:
                console.error(`Unknown filter type ${filterObject.type} for filter ${inputName}`);
            }
          } else {
            // filter block isn't rendered, create a new one
            switch (filterObject.type) {
              case 'list': {
                const options = Object.entries(filterObject.data);
                const $block = $($('#filter-block-template').html());
                $block.find('h5').html(
                    `${filterObject.name} <span class="quantityIndicator">(${options.length})</span>`);
                $block.attr('data-field', inputName);

                options.forEach(([value, { title, count }]) => {
                  const $checkbox = renderCheckbox(inputName, value, title, count);

                  $block.find('section > section').append($checkbox);
                });

                $block.insertBefore('.productsFilter_footer');
                break;
              }
              case 'option': {
                const options = Object.entries(filterObject.data);
                const $block = $($('#filter-block-template').html());
                $block.find('h5').html(
                    `${filterObject.name} <span class="quantityIndicator">(${options.length})</span>`);
                $block.attr('data-field', inputName);

                const $select = renderSelect(inputName, options);

                $block.find('section > section').append($select);

                $block.insertBefore('.productsFilter_footer');
                fancyDropdown.init($block);
                break;
              }
              case 'range':
                const $block = $($('#filter-block-template').html());
                $block.find('h5').html(`${filterObject.name}`);
                $block.attr('data-field', inputName);

                const values = filterObject.data.map(value => parseInt(value, 10));

                const min = Math.min(...values);
                const max = Math.max(...values);

                const $range = $($('#filter-range-template').html());
                $range.find('input[type="hidden"]').attr('name', inputName).val(`${min}:${max}`);
                $range.find('input[type="number"]').eq(0).attr('min', min).attr('max', max).val(min);
                $range.find('input[type="number"]').eq(1).attr('min', min).attr('max', max).val(max);

                if (inputName !== 'defaultPrice') { // € should be shown only for defaultPrice
                  $range.find('.rangeInput_values_input_suffix').remove();
                }

                $block.find('section > section').append($range);

                $block.insertBefore('.productsFilter_footer');
                rangeInput.init($block);
                break;
              default:
                console.error(`Unknown filter type ${filterObject.type} for filter ${inputName}`);
            }
          }
        }
      });
    }

    async function fetchProducts() {
      if (cancelController) {
        cancelController.abort();
      }
      $(selector.PAGINATION).hide();
      $(selector.EMPTY).hide();
      $(selector.LOADING).show();
      let signal;

      if (AbortController) {
        const controller = new AbortController();
        signal = controller.signal;
        cancelController = controller;
      }

      const query = searchQuery ? `q=${searchQuery}&` : '';

      try {
        const {
          data,
          meta: { filters, pagination },
        } = await (
            await fetch(
                `/api/products?${query}${$(
                    selector.FORM,
                ).serialize()}&page=${page}`,
                { signal },
            )
        ).json();

        $(selector.LOADING).hide();

        //updateSearchResultsCount(data.length);
        updatePagination(pagination);
        if (data.length > 0) {
          populateList(data);
          page += 1;
          $(selector.EMPTY).hide();
          $(selector.PAGINATION).show();
        } else {
          $(selector.EMPTY).show();
        }

        updateFilters(filters);

      } catch (error) {
        if (error.name === 'AbortError') {
          // doesn't matter
        }
      }
    }

    function closeFilter() {
      $form.css('display', '');
      $(document.body).removeClass('modal-visible');
    }

    const onSubmit = (event) => {
      event.preventDefault();
      resetList();
      fetchProducts();
    };

    const $form = $(selector.FORM);
    const $defaultCheckbox = $(selector.SELECT_DEFAULT);

    $form.on('submit', onSubmit);

    $form.on('change', debounce(300, (event) => {
      $(event.currentTarget).trigger('submit');
    }));

    $form.on('change', 'input[type=checkbox]', (event) => {
      const $checkbox = $(event.target);

      if ($checkbox.prop('name') === $defaultCheckbox.prop('name')) {
        if ($checkbox.is(':checked')) {
          $defaultCheckbox.prop('checked', false);
        } else {
          if ($(`input[name="${$checkbox.prop('name')}"]:checked`).length === 0) {
            $defaultCheckbox.prop('checked', true);
          }
        }
      }
    });

    $form.trigger('submit');

    const $paginationButton = $('button', selector.PAGINATION);
    $paginationButton.on('click', () => {
      fetchProducts();
    });

    const $select = $('.productsFilterSort select');

    $select.on('change', () => {
      $(selector.FORM).trigger('change');
    });

    $(selector.OPEN_BUTTON).on('click', () => {
      $(selector.FORM).show();
      $(document.body).addClass('modal-visible');
    });

    $(selector.CLOSE_BUTTON).on('click', (event) => {
      event.preventDefault();
      closeFilter();
    });

    $(selector.SUBMIT_BUTTON).on('click', (event) => {
      event.preventDefault();
      closeFilter();
    });

    // clear all filters
    $(selector.RESET_BUTTON).on('click', (event) => {
      event.preventDefault();
      const $form = $(selector.FORM);
      $form.get(0).reset();
      resetSelect();
      resetRange($(selector.RANGE));
      closeFilter();
      hideFilterTags();
      $form.trigger('submit');
    });

    // showing tag bar
    function showFilterTags() {
      $(selector.FILTER_TAGS).css('display', 'inline-flex');
    }

    // creating filter tag html
    function createFilterTag(title, value, swap = false) {
      const $tag = $($('#filter-tag-template').html());
      $tag.find('span').eq(0).html(`${title}`);
      $tag.find('span').eq(1).html(`${value}`);
      $tag.attr('title', title);
      $tag.attr('value', value);
      if (swap) {
        $tag.addClass('swap');
      }
      return $tag;
    }

    // handling filter tag on checkbox
    $form.on('change', 'input[type=checkbox]', (event) => {
      showFilterTags();
      const $checkbox = $(event.target);
      const title = $checkbox.parents('.js-productsFilterGroup').find(
          'header h5').clone().children().remove().end().text().trim();
      const value = $checkbox.next('span').find('> span').clone().children().remove().end().text().trim();
      const $tag = $(selector.FILTER_TAG).filter(`[title="${title}"][value="${value}"]`);

      if ($checkbox.is(':checked') && $tag.length === 0) {
        $(selector.FILTER_TAG_FIRST).append(createFilterTag(title, value));
      } else {
        $tag.remove();

        if ($(selector.FILTER_TAG).length < 1) {
          hideFilterTags();
        }
      }

    });

    // handling filter tag on select
    $form.on('change', '.js-fancyDropdown', function() {
      showFilterTags();
      const $options = $(this).siblings($(selector.SELECT_ITEMS)).find($(selector.SELECT_LIST));
      const title = $(this).parents('.js-productsFilterGroup').find(
          'header h5').clone().children().remove().end().text().trim();
      const value = $options.find('.active').attr('data-value');
      const $tag = $(selector.FILTER_TAG).filter(`[title="${title}"][value="${value}"]`);
      const $swapTag = $(selector.FILTER_TAG_SWAP).filter(`[title="${title}"]`);
      const $titleTag = $(selector.FILTER_TAG).filter(`[title="${title}"]`);
      const tagTitle = $titleTag.attr('title');

      if (!$options.children().first().hasClass('active') && $tag.length === 0) {
        if ($(selector.FILTER_TAG).hasClass('swap') && title === tagTitle) {
          $swapTag.replaceWith(createFilterTag(title, value, true));
        } else {
          $(selector.FILTER_TAG_FIRST).append(createFilterTag(title, value, true));
        }
      } else {
        $tag.remove();
      }

      if (value === '') {
        $titleTag.remove();

        if ($(selector.FILTER_TAG).length < 1) {
          hideFilterTags();
        }
      }

    });

    // handling filter tag on range
    $form.on('change', '.js-rangeInput', function() {
      showFilterTags();
      const title = $(this).parents('.js-productsFilterGroup').find(
          'header h5').clone().children().remove().end().text().trim();
      const min = $(this).find('input[type="number"]').eq(0).val();
      const max = $(this).find('input[type="number"]').eq(1).val();
      const value = min + '-' + max;
      const minValue = $(this).find('input[type="number"]').eq(0).attr('min');
      const maxValue = $(this).find('input[type="number"]').eq(0).attr('max');
      const $tag = $(selector.FILTER_TAG).filter(`[title="${title}"][value="${value}"]`);
      const $swapTag = $(selector.FILTER_TAG_SWAP).filter(`[title="${title}"]`);
      const $titleTag = $(selector.FILTER_TAG).filter(`[title="${title}"]`);
      const tagTitle = $titleTag.attr('title');

      if (max === maxValue && min === minValue) {
        $swapTag.remove();
      } else {
        if ($tag.length === 0) {
          if ($(selector.FILTER_TAG).hasClass('swap') && title === tagTitle) {
            $swapTag.replaceWith(createFilterTag(title, value, true));
          } else {
            $(selector.FILTER_TAG_FIRST).append(createFilterTag(title, value, true));
          }
        } else {
          $tag.remove();
        }
      }

      if ($(selector.FILTER_TAG).length < 1) {
        hideFilterTags();
      }

    });

    // closing filter tags with x button
    $(document).on('click', '.js-filterTagsClose', function() {
      const title = $(this).parent().attr('title');
      const value = $(this).parent().attr('value');
      const $checkbox = $form.find('input[type=checkbox]');
      const $select = $form.find('select');
      const $range = $form.find($(selector.RANGE));

      $(this).parent().remove();

      // closing checkbox tags
      $checkbox.each(function() {
        const filterTitle = $(this).parents('.js-productsFilterGroup').find(
            'header h5').clone().children().remove().end().text().trim();
        const filterValue = $(this).next('span').find('> span').clone().children().remove().end().text().trim();

        if (title === filterTitle && value === filterValue) {
          $(this).prop('checked', false);
        }
      });

      // closing select tags
      $select.each(function() {
        const $container = $(this).closest($(selector.SELECT_CONTAINER));
        const $options = $container.find($(selector.SELECT_LIST));
        const filterTitle = $(this).parents('.js-productsFilterGroup').find(
            'header h5').clone().children().remove().end().text().trim();
        const filterValue = $options.find('.active').attr('data-value');
        const $label = $container.find($(selector.SELECT_LABEL));
        const $all = $options.find('li').eq(0);
        const $current = $container.find($(selector.SELECT_CURRENT_TITLE));

        if (title === filterTitle && value === filterValue) {

          $label.removeClass('fancyDropdown_display_label-filled');
          $all.siblings().removeClass('active');
          $all.addClass('active');
          $(this).val('').trigger('change');
          $current.text($all.text());
        }

      });

      // closing reset tags
      $range.each(function() {
        const filterTitle = $(this).parents('.js-productsFilterGroup').find(
            'header h5').clone().children().remove().end().text().trim();
        const $minInput = $(this).find($(selector.RANGE_INPUT)).eq(0);
        const $maxInput = $(this).find($(selector.RANGE_INPUT)).eq(1);
        const min = $minInput.val();
        const max = $maxInput.val();
        const filterValue = min + '-' + max;

        if (title === filterTitle && value === filterValue) {
          resetRange($(this));
        }
      });

      if ($(selector.FILTER_TAG).length < 1) {
        hideFilterTags();
      }

      $form.trigger('submit');

    });

    // set current select option back to All
    function resetSelect() {
      const selectAllTitle = $(selector.SELECT_CURRENT_TITLE).eq(0).closest($(selector.SELECT_CONTAINER)).find(
          'ol li:first-child').text();
      $(selector.SELECT_CURRENT_TITLE).text(selectAllTitle);
    }

    // reset range
    function resetRange($container) {
      const $input = $container.find($(selector.RANGE_INPUT));
      const $finalInput = $container.find($(selector.RANGE_FINAL));
      const $minInput = $container.find($(selector.RANGE_INPUT)).eq(0);
      const $maxInput = $container.find($(selector.RANGE_INPUT)).eq(1);
      const minValue = $minInput.attr('min');
      const maxValue = $minInput.attr('max');

      $finalInput.val(minValue + ':' + maxValue);
      $container.css('--left', '-8px');
      $container.css('--right', 'calc(100% - 8px)');
      $minInput.val(minValue);
      $maxInput.val(maxValue);
      $input.trigger('change');
    }

    // hiding tag bar
    function hideFilterTags() {
      $(selector.FILTER_TAG).remove();
      $(selector.FILTER_TAGS).hide();
    }

  },
};
