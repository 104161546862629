export default {
  init() {
    const selector = {
      CART: "#minicart",
      CARTBODY: ".minicart_content",
      CLOSEBUTTON: ".minicart_close"
    };

    const classname = {
      VISIBLE: "minicart-visible"
    };

    function handleClickOffCart(event) {
      if (
        $(event.target).closest(selector.CARTBODY).length === 0 &&
        $(event.target).closest(selector.CARTBODY).length === 0
      ) {
        closeMinicart();
      }
    }

    function closeMinicart() {
      $(selector.CART).removeClass(classname.VISIBLE);
      window.location.hash = "";
      $(window).off('click', handleClickOffCart);
    }

    function openMinicart() {
      $(selector.CART).addClass(classname.VISIBLE);
      $(window).on('click', handleClickOffCart);
    }

    $(selector.CART).on("click", function(e) {
      if( $(e.target).is($(selector.CART)) || $(e.target).is($(selector.CLOSEBUTTON)) ) {
        closeMinicart();
      }
    });

    function checkHashForMinicart() {
      const hash = window.location.hash;

      if (hash === selector.CART) {
        openMinicart();
      }
    }

    checkHashForMinicart();
    window.addEventListener("hashchange", checkHashForMinicart);
  }
};
