// Lets you deselect radiobuttons by clicking on selected ones
// selects the "default" radiobutton on deselection is such exists
export default {
  init() {
    const selector = {
      CONTAINER: '.js-deselectableRadioList',
      INPUT: 'input[type=radio]',
      // an external radio that will be selected when the visible ones are deselected - to have a default value
      DEFAULT: '.js-deselecratbleRadioButton-default'
    };

    const $containers = $(selector.CONTAINER);

    const makeRadiosDeselectableByName = (name) => {
      $(`input[name=${name}]`).on('click', (event) => {
          const $target = $(event.target);

          if($target.prop('previousValue')){
            const defaultButton = $(`input[name=${name}]${selector.DEFAULT}`).get(0);
            if(defaultButton) {
              defaultButton.checked = true;
            } else {
              event.target.checked = false;
            }
            $target.closest('form').trigger('change');
          } else {
              $(`input[name=${name}]`).prop('previousValue', false);
          }

          $target.prop('previousValue', event.target.checked);
      });
    };

    $containers.each((_, container) => {
      const $container = $(container);

      const inputName = $container.find(selector.INPUT).first().prop('name');

      makeRadiosDeselectableByName(inputName);
    })
  }
}
