export default {
  init(base = document.body) {
    const selector = {
      PLUS: ".js-plusButton",
      MINUS: ".js-minusButton",
      INPUT: ".js-productCounter",
      CONTAINER: ".js-counter",
      KIND_LABEL: ".js-counterKindSelectorLabel",
      KIND_LABEL_TEXT: '.productQuantityCounter_kindSelector_label_text',
      KIND_OPTIONS: '.productQuantityCounter_kindSelector_options',
      KIND_OPTION: '.productQuantityCounter_kindSelector_options li',
      QTY_INPUT: '.productQuantityCounter_qty'
    };

    const classname = {
      KIND_OPTIONS_ACTIVE: 'productQuantityCounter_kindSelector_options-active'
    }

    const $counters = $(selector.CONTAINER, base);

    $counters.each((_, counter) => {
      const $counter = $(counter);
      const $plus = $(selector.PLUS, $counter);
      const $minus = $(selector.MINUS, $counter);
      const $input = $(selector.INPUT, $counter);
      const $kindLabel = $(selector.KIND_LABEL, $counter);
      const $kindLabelText = $(selector.KIND_LABEL_TEXT, $counter);
      const $kindOptionsContainer = $(selector.KIND_OPTIONS, $counter);
      const $kindOptions = $(selector.KIND_OPTION, $counter);
      const $qtyInput = $(selector.QTY_INPUT, $counter);

      function closeKindOptions() {
        $(window).off('click', handleSideclick);
        $kindOptionsContainer.removeClass(classname.KIND_OPTIONS_ACTIVE);
      }

      function handleSideclick(event) {
        if (
          $(event.target).closest(selector.KIND_OPTIONS).length === 0
          && $(event.target).closest(selector.KIND_LABEL).length === 0
        ) {
          closeKindOptions();
        }
      }

      function openKindOptions() {
        $kindOptionsContainer.addClass(classname.KIND_OPTIONS_ACTIVE);

        $(window).on('click', handleSideclick);
      }

      function adjustQtyValue() {
        const text = $kindLabelText.text();
        if (text === 'Cases') {
          $qtyInput.val(parseInt($input.val(), 10) * parseInt($input.data('quantityincase'), 10));
        } else {
          $qtyInput.val($input.val());
        }
      }

      $plus.click(() => {
        let count = $input.val();
        if (count < 1) $input.val(1);
        else $input.val(++count);
        adjustQtyValue();
      });

      $minus.click(() => {
        let count = $input.val();
        if (count > 1) $input.val(--count);
        adjustQtyValue();
      });

      $kindLabel.on('click', () => {
        if ($kindOptionsContainer.hasClass(classname.KIND_OPTIONS_ACTIVE)) {
          closeKindOptions();
        } else {
          openKindOptions();
        }
      });

      $input.on('change', adjustQtyValue);

      $kindOptions.on('click', (event) => {
        const $target = $(event.target);
        const text = $target.text();

        closeKindOptions();
        $kindOptions.removeClass('active');
        $target.addClass('active');
        $kindLabelText.text(text);
        adjustQtyValue();
      });
    });
  }
};
