export default {
  init() {
    const $input = $(".textInput_input");

    function changeTextInputLabel() {
      const label = $("label[for='" + $(this).attr("id") + "']");
      if ($(this).val() !== "") label.addClass("textInput_label-filled");
      else label.removeClass("textInput_label-filled");
    }

    $(document).ready(function() {
      $input.each(changeTextInputLabel);
    });

    $input.change(changeTextInputLabel);
  }
};
