export default {
  init($base = $(document.body)) {
    const selector = {
      INPUT: ".js-fancyDropdown",
      DISPLAY: ".fancyDropdown_display",
      CONTAINER: ".fancyDropdown",
      VALUE: ".fancyDropdown_display_input",
      FILTER: ".fancyDropdown_filter",
      LABEL: ".fancyDropdown_display_label",
      ITEMS: ".fancyDropdown_items ol"
    };

    const classname = {
      OPEN: "fancyDropdown-open",
      ACTIVE: "active",
      HIDDEN: "hidden",
      FILLED_LABEL: "fancyDropdown_display_label-filled",
      HIGHLIGHTED: "highlighted"
    };

    const dropdownSideClick = event => {
      if ($(event.target).closest(selector.CONTAINER).length === 0) {
        closeDropdown();
      }
    };

    const resetFilter = $container => {
      $(`.${classname.HIDDEN}`, $container).removeClass(classname.HIDDEN);
      $(selector.FILTER, $container).val("");
    };

    const closeDropdown = () => {
      const $openDropdown = $(`.${classname.OPEN}`);

      $openDropdown.removeClass(classname.OPEN);
      $(window).off("click", dropdownSideClick);
      resetFilter($openDropdown);
    };

    const dropdownWrappingHtml = select => `
      <div class="fancyDropdown_display">
        <div class="fancyDropdown_display_input js-fancyDropdown_current">${
          select.selectedOptions[0].text
        }</div>
        <div class="fancyDropdown_display_label${
          select.value !== "" ? ` ${classname.FILLED_LABEL}` : ""
        } js-fancyDropdown_label">${select.title}</div>
      </div>
      <div class="fancyDropdown_items js-fancyDropdown_items">
        <input class="fancyDropdown_filter" type="text" placeholder="Search" />
        <ol class="js-fancyDropdown_list">
        ${[...select.options]
          .map(
            option =>
              `<li class="${
                option.value === select.value ? "active" : ""
              }" data-value="${option.value}">${option.text}</li>`
          )
          .join("")}
        </ol>
      </div>
    `;

    const handleItemClick = event => {
      const $item = $(event.target);
      const $container = $item.closest(selector.CONTAINER);
      const input = $container.find(selector.INPUT).get(0);

      $item
        .addClass(classname.ACTIVE)
        .siblings()
        .removeClass(classname.ACTIVE);
      input.value = event.target.dataset.value;
      $(selector.VALUE, $container).text(input.selectedOptions[0].text);
      if (input.value === "") {
        $(selector.LABEL, $container).removeClass(classname.FILLED_LABEL);
      } else {
        $(selector.LABEL, $container).addClass(classname.FILLED_LABEL);
      }
      $(input).trigger('change');
      closeDropdown();
    };

    const handleItemMouseover = event => {
      const $items = $(event.target)
        .siblings()
        .removeClass(classname.HIGHLIGHTED);
    };

    const handleDisplayClick = event => {
      const $container = $(event.target).closest(selector.CONTAINER);
      const $filter = $container.find(selector.FILTER);

      if ($container.hasClass(classname.OPEN)) {
        closeDropdown();
      } else {
        $container.addClass(classname.OPEN);
        $filter.focus();

        event.stopPropagation();
        $(window).on("click", dropdownSideClick);
      }
    };

    const handleFilterInput = event => {
      const $items = $(event.target)
        .parent()
        .find("li");

      const { value } = event.target;

      if (value === "") {
        $items.removeClass(classname.HIDDEN);
      } else {
        $items.each((_, item) => {
          const $item = $(item);

          const matchesFilter = $item
            .text()
            .toLowerCase()
            .includes(value.toLowerCase());

          $item.toggleClass(classname.HIDDEN, !matchesFilter);
        });
      }

      $items.removeClass(classname.HIGHLIGHTED);
    };

    const handleFilterKeydown = event => {
      const $items = $(event.target)
        .parent()
        .find("li");
      const $visibleItems = $items.filter(`:not(.${classname.HIDDEN})`);
      let highlightedIndex = $visibleItems.index(
        $visibleItems.filter(`.${classname.HIGHLIGHTED}`)
      );

      switch (event.which) {
        case 27: // escape
          event.target.value = "";
          $items.removeClass(classname.HIDDEN);
          break;
        case 13: // enter
          event.preventDefault();

          let $highlighted = $items.filter(`.${classname.HIGHLIGHTED}`);

          if ($highlighted.length === 0) {
            $highlighted = $items.filter(`:not(.${classname.HIDDEN})`).first();
          }

          $highlighted.trigger("click");
          break;
        case 38: // up
          event.preventDefault();

          if (highlightedIndex <= 0) {
            highlightedIndex = $visibleItems.length - 1;
          } else {
            highlightedIndex -= 1;
          }

          $visibleItems
            .removeClass(classname.HIGHLIGHTED)
            .eq(highlightedIndex)
            .addClass(classname.HIGHLIGHTED);
          break;
        case 40: // down
          event.preventDefault();

          if (
            highlightedIndex === $visibleItems.length - 1 ||
            highlightedIndex === -1
          ) {
            highlightedIndex = 0;
          } else {
            highlightedIndex += 1;
          }

          $visibleItems
            .removeClass(classname.HIGHLIGHTED)
            .eq(highlightedIndex)
            .addClass(classname.HIGHLIGHTED);
          break;
      }
    };

    const $inputs = $(selector.INPUT, $base);

    $inputs.each((_, input) => {
      const $input = $(input);

      $input.wrap('<div class="fancyDropdown formField" />');

      const $container = $input.parent();

      $container.append(dropdownWrappingHtml(input));

      const $itemsContainer = $(selector.ITEMS, $container);
      const $items = $("li", $container);
      const $display = $(selector.DISPLAY, $container);
      const $filter = $(selector.FILTER, $container);

      $items.on("click", handleItemClick).on("mouseover", handleItemMouseover);

      $display.on("click", handleDisplayClick);

      $filter.on("input", handleFilterInput).on("keydown", handleFilterKeydown);
    });
  }
};
