export default {
  init() {
    const selector = {
      FORM: '.js-newsletter-form',
      SEND: '.js-newsletter-form-send'
    };

    $(document).ready(function() {
      $(selector.FORM).on('submit', function(e) {
        e.preventDefault(); // Prevent the form from actually submitting

        let _this =$(this);
        const form = $(e.target);
        const url = form.attr('action');

        $.ajax({
          url: url,
          method: 'POST',
          data: form.serialize(),
          dataType: 'json',
        }).done(function (result) {
          if(result.errMsg) {
            _this.find('.newsletterForm-response').html('<p>' + result.errMsg + '</p>');
            return;
          }
          _this.html("<p class='newsletterForm-success'>" + result.successMsg + '</p>');
        }).fail(function(e) {
          console.log(e);
        })
      });
    });

  }
}