import OverLayer from '../components/OverLayer';

const defaults = {
  selector: {
    CONTAINER: '.js-sidePanel_container',
    BG: '.js-sidePanel_bg',
  },
  className: {
    PREP_FOR_SHOW: 'st-prepFor-show',
    SHOW: 'st-show',
  },
};

class SidePanel {
  constructor(el, opts = {}) {
    this.config = $.extend(defaults, opts);
    this.DOM = {el: el}
    this.DOM.$el = $(this.DOM.el);
    this.DOM.$container = this.DOM.$el.find(this.config.selector.CONTAINER);
    this.DOM.$bg = this.DOM.$el.find(this.config.selector.BG);
    this.DOM.$toggles = this.config.$toggles;
    this.overLayer = null;
    this.init();
  }

  init() {
    const _self = this;
    const overLayerConfig = {
      conductShowAnimation: function (args) {
        const { element, finish, onTransitionEndOnce } = args;
        _self.DOM.$container.addClass(_self.config.className.PREP_FOR_SHOW);
        _self.DOM.$bg.addClass(_self.config.className.PREP_FOR_SHOW);
        // reflow
        element.offsetHeight;
        onTransitionEndOnce(_self.DOM.$bg[0], () => finish(), 400);
        _self.DOM.$container.addClass(_self.config.className.SHOW);
        _self.DOM.$bg.addClass(_self.config.className.SHOW);
      },

      conductHideAnimation: function (args) {
        const { finish, onTransitionEndOnce } = args;

        onTransitionEndOnce(_self.DOM.$bg[0], () => {
          _self.DOM.$container.removeClass(_self.config.className.PREP_FOR_SHOW);
          _self.DOM.$bg.removeClass(_self.config.className.PREP_FOR_SHOW);
          finish();
        }, 400);

        _self.DOM.$container.removeClass(_self.config.className.SHOW);
        _self.DOM.$bg.removeClass(_self.config.className.SHOW);
      },
    };

    _self.overLayer = new OverLayer(this.DOM.el, overLayerConfig);

    this.DOM.el.addEventListener('olshow', () => {
      this.DOM.$toggles.addClass('st-close');
    });

    this.DOM.el.addEventListener('olhide', () => {
      this.DOM.$toggles.removeClass('st-close');
    });

    this.DOM.$toggles.on('click', function(e) {
      e.preventDefault();
      _self.overLayer.toggle();
    })

    this.DOM.$bg.on('click', function(e) {
      e.preventDefault();
      _self.overLayer.hide();
    });
  }
}

export default SidePanel;
