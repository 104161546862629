export default {
  init() {
    const selector = {
      CONTAINER: ".js-allNewsContainer",
      LOAD_BUTTON: ".js-loadNewsButton",
      ARTICLES_COUNTER: ".js-articlesCounter",
      ARTICLES_TOTAL: ".js-articlesTotal",

      CONTAINER_POPULAR: ".js-popularNewsContainer"
    };

    const $newsContainer = $(selector.CONTAINER);
    const $loadNewsButton = $(selector.LOAD_BUTTON);
    const $articlesTotal = $(selector.ARTICLES_TOTAL);
    const $articlesCounter = $(selector.ARTICLES_COUNTER);
    const $popularContainer = $(selector.CONTAINER_POPULAR);

    let nextUrl = "";
    let articlesCount = 0;

    async function getAllNews() {
      const { data, meta } = await (await fetch("/api/articles?page=1")).json();
      if (data.length) {
        $newsContainer.empty();
        $.each(data, (key, item) => {
          $newsContainer.append(item.html);
        });

        articlesCount = meta.pagination.count;

        $articlesCounter.text(articlesCount);
        $articlesTotal.text(meta.pagination.total);

        nextUrl = meta.pagination.links.next;
      }
    }

    async function getNextNews() {
      const { data, meta } = await (await fetch(nextUrl)).json();
      if (data.length) {
        $.each(data, (_, item) => {
          $newsContainer.append(item.html);
        });

        articlesCount = articlesCount + meta.pagination.count;
        $articlesCounter.text(articlesCount);
      }

      if (!meta.pagination.links.next) $loadNewsButton.hide();
      else nextUrl = meta.pagination.links.next;
    }

    async function getPopularNews() {
      const { data } = await (await fetch("/api/articles/popular")).json();
      if (data.length) {
        $popularContainer.empty();

        $.each(data.slice(0, 3), (_, item) => {
          $popularContainer.append(item.html);
        });
      }
    }

    if ($newsContainer.length) {
      getAllNews();
    }
    if($popularContainer.length) {
      getPopularNews();
    }

    $loadNewsButton.click(e => {
      e.preventDefault();
      getNextNews();
    });
  }
};
