export default {
  init() {
    const selector = {
      ADDRESS_FORM_TYPE: ".js-addressType",

      IS_SEPARATE_BILLING: ".js-isSeparateBilling",
      INVOICE_SECTION: ".js-invoiceSection",
      INVOICE_ADD_NOTES_BUTTON: ".js-invoiceAddNotesButton",
      INVOICE_NOTES_INPUT: ".js-invoiceNotesInput",

      ADDRESS_ADD_NEW: ".js-addressAddNew",
      ADDRESS_NAV_LINK: ".js-addressesNavLink",
      ADDRESS_NAV_LINK_ACTIVE_CLASS: ".cartFormNav_link-active",
      PERSONAL_FORM_FIELDS: ".js-deliveryPersonal",
      BUSINESS_FORM_FIELDS: ".js-deliveryBusiness",

      PERSONAL_NOTES_BUTTON: ".js-personalNotesButton",
      PERSONAL_NOTES_INPUT: ".js-personalNotesInput",

      IS_SEPARATE_BILLING_SELECT: '.js-isSeparateBillingAddress',
      SEPARATE_BILLING_ADDRESS_RADIOS: '.js-separateBillingAddressRadios'
    };

    const $isSeparateBilling = $(selector.IS_SEPARATE_BILLING);
    const $invoiceSection = $(selector.INVOICE_SECTION);
    const $invoiceAddNotesButton = $(selector.INVOICE_ADD_NOTES_BUTTON);
    const $invoiceNotesInput = $(selector.INVOICE_NOTES_INPUT);
    const $isSeparateBillingSelect = $(selector.IS_SEPARATE_BILLING_SELECT);
    const $separateBillingAddressRadios = $(selector.SEPARATE_BILLING_ADDRESS_RADIOS);

    $isSeparateBilling.change(() => {
      $invoiceSection.slideToggle();
    });

    $invoiceAddNotesButton.click(e => {
      e.preventDefault();
      $invoiceNotesInput.slideToggle();
    });

    const $addressNavLink = $(selector.ADDRESS_NAV_LINK);
    const $addressNavLinkActiveClass = $(selector.ADDRESS_NAV_LINK_ACTIVE_CLASS);
    const $personalFormFields = $(selector.PERSONAL_FORM_FIELDS);
    const $businessFormFields = $(selector.BUSINESS_FORM_FIELDS);
    const $addressFormType = $(selector.ADDRESS_FORM_TYPE);
    const $addressAddNew = $(selector.ADDRESS_ADD_NEW)
    const $addressAddNewHref = $(selector.ADDRESS_ADD_NEW).attr('href')

    $addressNavLink.click(function (e) {
      e.preventDefault();

      $addressNavLink.removeClass("cartFormNav_link-active");
      $(this).addClass("cartFormNav_link-active");

      $personalFormFields.hide();
      $businessFormFields.hide();
      $invoiceSection.hide();

      $(".js-" + $(this).attr("name")).show();

      if ($(this).attr("name") === "deliveryBusiness") {
        $addressAddNew.attr('href', $addressAddNewHref + '&type=business')
        $addressFormType.val("business");
      } else {
        $addressAddNew.attr('href', $addressAddNewHref + '&type=personal')
        $addressFormType.val("personal");
      }
    });

    if ($addressNavLinkActiveClass.attr('name') === "deliveryBusiness") {
      $addressAddNew.attr('href', $addressAddNewHref + '&type=business')
    } else {
      $addressAddNew.attr('href', $addressAddNewHref + '&type=personal')
    }

    const $personalNotesButton = $(selector.PERSONAL_NOTES_BUTTON);
    const $personalNotesInput = $(selector.PERSONAL_NOTES_INPUT);

    $personalNotesButton.click(e => {
      e.preventDefault();
      $personalNotesInput.slideToggle();
    });

    $isSeparateBillingSelect.on('change', (event) => {
      if (event.target.checked) {
        $separateBillingAddressRadios.css('display', '');
      } else {
        $separateBillingAddressRadios.css('display', 'none');
        $separateBillingAddressRadios.find('input:radio').each((_, radio) => {
          radio.checked = false;
        });
      }
    })
  }
};
