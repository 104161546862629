export default {
  init() {
    const submitBtn = $(".js-paymentBtn");
    const checkbox = $(".js-termsAndConditionsCheckbox");
    const checkboxLabel = $(".js-termsCheckboxLabel");

    submitBtn.on('click', e => {
      if (!checkbox.prop("checked")) {
        e.preventDefault();
        checkboxLabel.find("span").addClass("unchecked");
      } else {
        document.eventPurchase();

        checkboxLabel.find("span").removeClass("unchecked");
      }
    });

    checkbox.on('change', e => {
      if(e.target.value) {
        checkboxLabel.find("span").removeClass("unchecked")
      }
    });
  }
};
