export default {
  init($item) {
    const selector = {
      CONTAINER: ".js-ordersListItem",
      HEADER: ".ordersList_item_header"
    };

    const classname = {
      OPEN: "ordersList_item-open"
    };

    const $containers = $($item || selector.CONTAINER);

    $(selector.HEADER, $containers).on("click", event => {
      $(event.target)
        .closest(selector.CONTAINER)
        .toggleClass(classname.OPEN);
    });
  }
};
