export default {
  init() {
    const $form = $(".js-contactUsForm");

    /*if ($form.length) {
      $form.find('.wf-label').addClass('textInput_label')
    }*/

    $form.submit(function (ev) {
      // Prevent the form from actually submitting
      ev.preventDefault();

      $('.js-contactFormError').remove()
      var data = $(this).serialize();

      // Send it to the server
      $.post('/wheelform/message/send',
        data,
        function (response) {
          if (response.success) {

            $form.after('<p class="p-3 mt-4 bg-success text-white">' + response.message + '</p>')
            $form.remove()

            // send event
            document.eventLead();

          } else {
            $.each(response.errors, function (key, value) {
              $('.wf-field[name="' + key + '"]').after('<p class="textInput_errorMessage js-contactFormError" style="display: block">' + value[0] + '</p>')
            });
          }
        }
      );
    });
  }
};
