import { debounce } from "throttle-debounce";
import counter from "./counter";

export default {
  init() {
    const selector = {
      INPUT: ".js-searchDropdown",
      CONTAINER: ".js-searchResults",
      SUBMIT: '.js-searchSubmit',
      FIELD: '.js-searchField'
    };

    const classname = {
      OPEN: "searchResults-open"
    };

    const apiUrl = "/api/search";
    const $searchFields = $(selector.FIELD);

    let cancelController;

    const $resultsContainer = $(selector.CONTAINER);

    const handleClickOffSearch = event => {
      if (
        $(event.target).closest(selector.INPUT).length === 0 &&
        $(event.target).closest(selector.CONTAINER).length === 0
      ) {
        closeDropdown();
      }
    };

    const closeDropdown = () => {
      const $openDropdown = $(`.${classname.OPEN}`);

      $openDropdown.removeClass(classname.OPEN);
      $(window).off("click", handleClickOffSearch);
    };

    const searchResultsHtml = (products, articles, query) => `
      <div class="searchResults_wrapper">
        <ol>
        ${products
          .map(item => `<li class="" data-value="${item.id}">${item.html}</li>`)
          .join("")}
          ${
            articles
              ? `
          <h4 class="searchResults_title">Articles</h4>
          ${articles
            .map(
              (item, index) =>
                `<li class="" data-value="${index}"><div class="searchResults_articleWrapper">${item}</div></li>`
            )
            .join("")}`
              : ""
          }
        </ol>
        <a href="/search?q=${query}" class="button searchResults_button">View all results</a>
      </div>`;

    function populateResults(products, articles, query) {
      $resultsContainer.empty();
      if (!products.length && articles === undefined) {
        $resultsContainer.append(`
          <p class="searchResults_emptyInfo">
            No matching products or articles found
          </p>`);
      } else {
        $resultsContainer.append(searchResultsHtml(products, articles, query));
        counter.init($resultsContainer);
      }
    }

    async function fetchResults(query) {
      if (cancelController) {
        cancelController.abort();
      }
      const requestUrl = new URL(apiUrl, window.location.href);
      requestUrl.searchParams.append("q", query);
      requestUrl.searchParams.append("show", "list");

      let signal;

      if (AbortController) {
        const controller = new AbortController();
        signal = controller.signal;
        cancelController = controller;
      }

      const { data, meta } = await (await fetch(requestUrl, { signal })).json();
      populateResults(data, meta.articles, query);
    }

    $searchFields.each((_, field) => {
      const $input = $(selector.INPUT, field);
      const $submit = $(selector.SUBMIT, field);
      const $results = $(selector.CONTAINER, field);

      $input.on(
        "keyup",
        debounce(300, function() {
          if ($input.val() === "") {
            closeDropdown();
          } else {
            if (!$results.hasClass(classname.OPEN)) {
              $results.addClass(classname.OPEN);
              $(window).on("click", handleClickOffSearch);
            }
            fetchResults($input.val());
          }
        })
      );

      $submit.on('click', () => {
        window.location.href = `/search?q=${$input.val()}`;
      });
    });
  }
};
